<template>
	<div>
		<myheader></myheader>
		<mybanner/>
		<div class="index">
			<router-view/>
		</div>

		<myfooter></myfooter>
		<myright></myright>

	</div>
</template>

<script>
import myheader from '../components/header.vue'
import myfooter from '../components/footer.vue'
import myright from '../components/right.vue'
import mybanner from '../components/banner.vue'
export default {

  components: {
    myheader, myfooter, myright, mybanner
  },

  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
	.index{
		min-width: 1200px;
		width: 100%;
		// margin: 0 auto;
		padding-top: 20px;
		background-color: #f9f9f9;

	}
</style>
