<template>
	<div class="banner">
		<div id="slider">
		    
			<el-carousel height="400px" :interval="8000">
				<el-carousel-item v-for="(item,i) in imgs" :key="i">
					<img :src="item.attachment" class="pic" @click="tourl(item)">
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>

</template>

<script>
import {bannerListAPI} from "@/api/index"
export default {

  data () {
   
    return {
      imgs: [],
      currentIndex: 0,
      buttons: [0, 1, 2],
      timer: null
    }
  },
  methods: {
   tourl(item){
	   switch(item.type_id){
			case 1:
				this.todetails(item.goods_id)
			break;
			case 2:
				 this.$router.push({ path: '/bannerinfo',query:{id:item.id} })
			break;
			case 3:
				this.toimginfo(item.goods_id)
			break;
			case 4:
				this.$router.push({ path: '/searchlist',query:{content:item.search_info,banner:1} })
			break;
		}
   },
   todetails(id){
		this.$router.push({ path: '/details',query:{id} })
	},
	toimginfo(id){
		this.$router.push({ path: '/imginfo',query:{id} })
	},
	getlist(){
		bannerListAPI().then(res=>{
			this.imgs=res.cateList.filter((item)=> item.resource==1)
		})
	}
  },
  mounted () {
	  this.getlist()

  }
}
</script>

<style lang="scss">
.banner{
	margin-top: 1px;
	#slider{
	    position: relative;
	    width: 100%;
		min-width: 1200px;
	    overflow: hidden;
	    height: 400px;
	  
	  .el-carousel__container{
		  width: 100%;
		  
	  }
	  ul{
		.el-carousel__button{

			height:8px;
			border-radius: 4px;
			opacity:1;
			width:30px;
			background-color: #fff;
		}
		.is-active .el-carousel__button{
			background-color: #ff7c00;
			width:55px;
		}
	}
	  .slider-imgcontainer {
	    position: absolute;
	    left: 0;
	    width: 400%;
	    height: 380px;
	    transition: left .5s linear;
	  }
	  
	  .pic{
		  min-height: 400px;
		    width: 100%;
	    // width: 25%;
			min-width: 1200px;		
	  }
		
	}
}
</style>
